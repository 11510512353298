import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Box, Container, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { url } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { LoaderOverlay } from '../utils/components';


//const paymentTypes = [{ value: 'pix', label: 'Pix' }, { value: 'cartao', label: 'Cartão de crédito' }]; // [2]

const masks = {
    '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
    '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
    '+595': { name: 'Paraguay', mask: '(xxx) xxx-xxx' },
    '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
    // Adicione outras máscaras conforme necessário
};

const PagamentoMercadoPago = ({ setIsLoggedIn, usePageTitle }) => {
    usePageTitle('PixTick | Gerar Cobrança');
    const navigate = useNavigate();
    //const [imgqrcode, setImgqrcode] = useState(''); // [1]
    const [phone, setPhone] = useState("");
    const [paymentType, setPaymentType] = useState("pix"); // [2]
    const [ddi, setDdi] = useState("+55");
    const [error, setError] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [descricao, setDescricao] = useState('');
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [loading, setLoading] = React.useState(true);
    const [idTransaction, setIdTransaction] = React.useState('');

    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                //console.log('teste> ' + token);
                if (token) {
                    await axios.get(url + '/api/v1/user/verifyToken', {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        })
                    });

                    setIsLoggedIn(true);

                } else {
                    setIsLoggedIn(false);
                    navigate('/signin');
                }
            } catch {
                setIsLoggedIn(false);
                navigate('/signin');

            } finally {
                setLoading(false);
            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        let formattedValue = '';

        // Remover caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');

        // Obter os centavos (últimos dois dígitos)
        const cents = numericValue.slice(-2);

        // Obter os reais (todos os dígitos exceto os últimos dois)
        const dollars = numericValue.slice(0, -2);

        // Formatar os reais com separadores de milhares
        for (let i = 0; i < dollars.length; i++) {
            formattedValue += dollars[i];
            if ((dollars.length - i - 1) % 3 === 0 && i !== dollars.length - 1) {
                formattedValue += '.';
            }
        }

        // Adicionar os centavos
        formattedValue += ',' + cents;

        setInputValue(formattedValue);
    };

    const handleDescricaoChange = (event) => {
        const { value } = event.target;
        setDescricao(value);
    }
    const handleNomeChange = (event) => {
        const { value } = event.target;
        setNome(value);
    }
    const handleSobrenomeChange = (event) => {
        const { value } = event.target;
        setSobrenome(value);
    }

    const normalizeInput = (value, ddi) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');

        // Obter a máscara correspondente ao DDI selecionado
        const mask = masks[ddi].mask;
        if (!mask) {
            // Se não houver uma máscara correspondente, retornar o valor atual sem formatação
            return currentValue;
        }

        let formattedValue = '';
        let maskIndex = 0;
        for (let i = 0; i < mask.length && maskIndex < currentValue.length; i++) {
            if (mask[i] === 'x') {
                // Se o caractere na máscara for 'x', adicionar o próximo dígito do valor atual
                formattedValue += currentValue[maskIndex];
                maskIndex++;
            } else {
                // Se o caractere na máscara não for 'x', adicionar o próprio caractere
                formattedValue += mask[i];
            }
        }
        return formattedValue;
    };

    const handleChange = (event) => {
        const { value } = event.target;
        //setPhone(value);
        setPhone(normalizeInput(value, ddi));

    };

    const handleDdiChange = (event) => {
        const { value } = event.target;
        setDdi(value);
        //handleReset();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (loading) return;
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`
            };
            const body = {
                numero: ((ddi + phone).replace(/[()\s+-]/g, "")),
                valor: inputValue,
                descricao: descricao,
                nomeCompleto: nome + ' ' + sobrenome,
                paymentType: paymentType

            };

            if (body.numero.length < 10 || body.valor === '' || body.descricao === '' || body.nomeCompleto === '') {
                setError('Preencha todos os campos');
                return;

            }

            setLoading(true);
            const { data } = await axios.post(url + '/api/v1/pagamento', body, { headers });
            setIdTransaction(data.id);

            console.log('teste2:', data.id);
            //const repostaPagamento = await axios.post(url + '/api/v1/pagamento', { numero: ((ddi + phone).replace(/[()\s+-]/g, "")), valor: inputValue, descricao: descricao }, { headers })
            setLoading(false);

        } catch (error) {
            setError('Erro ao gerar qr code, tente novamente!');
            setLoading(false);
        } finally {

            console.log('teste3:', loading);
        }
        console.log('teste4:', idTransaction);
        if (idTransaction !== 0) {
            navigate('/relatorio-pagamento/' + idTransaction);
        } else {
            navigate('/relatorio-pagamento');
        }

    };



    return (
        <Container maxWidth="sm" sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <LoaderOverlay loading={loading} color='green' />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                position: 'absolute', top: 0, left: '40%', transform: 'translateX(-35%)', textAlign: 'center',
                '@media (max-width: 600px)': { // xs
                    left: '5.5%',
                    transform: 'translateX(-3.4%)',
                },
                '@media (min-width: 601px) and (max-width: 960px)': { // sm
                    left: '20%',
                    transform: 'translateX(-10%)',
                },
                '@media (min-width: 961px) and (max-width: 1280px)': { // md
                    left: '30%',
                    transform: 'translateX(-25%)',
                },
                '@media (min-width: 1281px)': { // lg and up
                    left: '35%',
                    transform: 'translateX(-30%)',
                },
                marginTop: 10
            }}>
                <Typography sx={{ margin: 5 }} variant='h5'><strong>Gerar Link</strong></Typography>

                {/* <Radio  // [2]
                    checked={paymentType === 'pix'}
                    onChange={() => setPaymentType('pix')}
                    value="pix"
                    name="paymentType"
                    inputProps={{ 'aria-label': 'Pix' }}
                />
                <label>Pix</label>
                <Radio
                    checked={paymentType === 'cartao'}
                    onChange={() => setPaymentType('cartao')}
                    value="cartao"
                    name="paymentType"
                    inputProps={{ 'aria-label': 'Cartão de crédito' }}
                />
                <label>Cartão de crédito</label> */}

                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <TextField
                        sx={{ borderRadius: 10 }}
                        required
                        label="Informe valor"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="0,00"  // [4]
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: '20px', // Aqui você define o borderRadius desejado
                            }
                        }}

                    />
                </Box>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <TextField
                        required
                        label="Nome"
                        value={nome}
                        onChange={handleNomeChange}
                        placeholder="Ex: João"  // [4]
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: '20px', // Aqui você define o borderRadius desejado
                            }
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <TextField
                        required
                        label="Sobrenome"
                        value={sobrenome}
                        onChange={handleSobrenomeChange}
                        placeholder="Ex: da silva"  // [4]
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: '20px', // Aqui você define o borderRadius desejado
                            }
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <TextField
                        required
                        label="Descrição do pagamento"
                        value={descricao}
                        onChange={handleDescricaoChange}
                        placeholder="Ex: João"  // [4]
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: '20px', // Aqui você define o borderRadius desejado
                            }
                        }}
                    />

                </Box>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <FormControl sx={{ mr: 2, mt: 0.5 }}>

                        <Select
                            sx={{ borderRadius: 5, width: 150, marginRight: 2 }}
                            labelId="ddi-label"
                            id="ddi"
                            name="ddi"
                            value={ddi}
                            onChange={handleDdiChange}

                        >
                            {Object.keys(masks).map((countryCode) => (
                                <MenuItem key={countryCode} value={countryCode}>
                                    {`${countryCode} - ${masks[countryCode].name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{ flexGrow: 1, marginTop: 0.5 }}>

                        <TextField
                            required
                            label="Número de telefone"
                            type="tel"
                            placeholder={masks[ddi].mask}
                            value={phone}
                            fullWidth
                            name="phoneNumber"
                            onChange={handleChange}
                            InputProps={{
                                style: {
                                    borderRadius: '20px', // Aqui você define o borderRadius desejado
                                }
                            }}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </Box>
                </Box>
                <Button fullWidth type="submit" variant="contained" sx={{ borderRadius: 2.5, marginTop: 1 }}>Enviar</Button>


            </Box>
        </Container >



    );
};

export default PagamentoMercadoPago;