import React from 'react';
import { Drawer, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close, FilterList } from '@mui/icons-material';
import FilterComponentVertical from './FilterComponentVertical';

const useStyles = makeStyles({
  drawerPaper: {
    width: '300px',
    height: 'calc(100vh - 60px)', // subtract the height of the Navbar
    marginTop: '70px', // 100% of the viewport height
  },
});

export default function Sidebar2({ open, setOpen, filters, setFilters, onFilter, idpdvs }) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
      >
        <Typography variant="h6" gutterBottom component="div">Filtro</Typography>
        <FilterList />
      </IconButton>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.drawerPaper }} // apply the styles here

      >
        <IconButton onClick={handleDrawerClose}>
          <Close />
        </IconButton>
        <FilterComponentVertical filters={filters} setFilters={setFilters} onFilter={onFilter} idpdvs={idpdvs} />
      </Drawer>
    </div>
  );
};

