import { Box, CircularProgress } from "@mui/material";

export function LoaderOverlay({ loading, size = 100, color = '#fff' }) {

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 9999,
        visibility: loading ? 'visible' : 'hidden',
      }}
    >
      <CircularProgress size={size} style={{ color }} />
    </Box>
  );
}

export function HorizontalLine() {
  const lineStyle = {
    height: '2px',
    backgroundColor: 'black',
    width: '100%',
    margin: '20px 0',
  };

  return (
    <div>

      <div style={lineStyle}></div>  {/* Adiciona a linha personalizada */}

    </div>
  );
}


