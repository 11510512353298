import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Container, List, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { PAGE_SIZE, url } from '../utils/constants';
import { LoaderOverlay } from '../utils/components';


export default function PerfilList({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('PixTick | Perfis');
  const [open, setOpen] = useState(false);
  const [selectedPerfil, setSelectedPerfil] = useState(null);
  const [perfis, setPerfis] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState({
    descricao: '',
    nome: '',
    dataInicio: '',
    dataFim: '',
    telefone: '',
    id: '',
    idTransacao: '',
    idpdvs: [],
    status: 'Todos',
    geral: ''
  });

  const handleOpen = (perfil) => {
    setSelectedPerfil(perfil);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPerfil(null);
  };

  const handleEdit = (perfil) => {
    handleOpen(perfil);
  };

  const handleDelete = (id) => {
    // Lógica de exclusão
    console.log(`Excluindo perfil com id: ${id}`);
  };

  const handleSave = async () => {
    if (!selectedPerfil) return;

    try {
      setLoading(true);

      await axios.post(url + `/api/v1/user/atualizaDadosPerfil`, {
        user: { email: selectedPerfil.email },
        novosDados: selectedPerfil
      });

      // Atualiza a lista de perfis após a edição
      setPerfis((prevPerfis) =>
        prevPerfis.map((perfil) =>
          perfil.email === selectedPerfil.email ? { ...perfil, ...selectedPerfil } : perfil
        )
      );

      handleClose();
    } catch (error) {
      console.error("Erro ao salvar alterações", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get('page')) || 1;
    setCurrentPage(page);
  }, [location.search]);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        const queryParams = new URLSearchParams({
          page: currentPage,
          ...filters,
          status: filters.status === 'Todos' ? '' : filters.status
        });

        const response = await axios.get(url + `/api/v1/user/perfis?${queryParams}`, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        });
        const { data, totalcount } = response.data;

        console.log(totalcount);

        setPerfis(data);

        setTotalPages(Math.ceil(totalcount / PAGE_SIZE));

        //console.log(data);


        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
        navigate('/signin');
      } finally {
        setLoading(false);
        //console.log(transactions);

      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn, currentPage, filters]);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);

    navigate(`?page=${pageNumber}`);
  };

  // Aplicar máscara ao CNPJ
  const formatCNPJ = (value) => {
    return value
      .replace(/\D/g, '') // Remove tudo que não for número
      .replace(/^(\d{2})(\d)/, '$1.$2') // Adiciona ponto após os 2 primeiros números
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Adiciona ponto após os próximos 3 números
      .replace(/\.(\d{3})(\d)/, '.$1/$2') // Adiciona barra após os próximos 3 números
      .replace(/(\d{4})(\d)/, '$1-$2') // Adiciona traço antes dos últimos 2 números
      .slice(0, 18); // Garante que não ultrapasse 18 caracteres (formato do CNPJ)
  };

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return phoneNumber;

    // Definir máscaras para cada DDI
    const masks = {
      '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
      '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
      '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
      '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
      // Adicione outras máscaras conforme necessário
    };

    // Detectar o DDI do número de telefone
    let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
    if (!ddi) return phoneNumber; // Se não for encontrado nenhum DDI correspondente, retorne o número de telefone sem formatação
    phoneNumber = phoneNumber.replace(ddi, ''); // Remover o DDI do número de telefone
    // Obter a máscara correspondente ao DDI detectado
    const mask = masks[ddi].mask;

    // Remover caracteres não numéricos do número de telefone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Aplicar máscara ao número de telefone
    let formattedPhoneNumber = '';
    let maskIndex = 0;
    for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
      if (mask[i] === 'x') {
        formattedPhoneNumber += numericPhoneNumber[maskIndex];
        maskIndex++;
      } else {
        formattedPhoneNumber += mask[i];
      }
    }
    //ddi + formattedPhoneNumber;

    return ddi + ' ' + formattedPhoneNumber;
  }

  const formatCurrency = (value) => {
    if (!value) return value;

    // Formata o valor em moeda brasileira (pt-BR)
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  function getStatusColor(status) {
    switch (status) {
      case 'EMANALISE':
        return '#0000FF'; // Amarelo
      case 'ATIVO':
        return '#008000'; // Verde
      case 'Concluído':
        return '#0000FF'; // Azul
      case 'Falhou':
        return '#FF0000'; // Vermelho
      default:
        return '#FF0000'; // Cinza (ou qualquer outra cor padrão)
    }
  }

  const handlePerfilClick = (perfil) => {
    console.log(perfil);
    navigate(`/perfil-detalhado/${perfil._id}`)
    //setSelectedTransaction(transaction);
  };


  // const handleFilter = async () => {
  //   setLoading(true);
  //   setCurrentPage(1);

  //   try {
  //     const token = await localStorage.getItem('token');
  //     const queryParams = new URLSearchParams({
  //       page: currentPage,
  //       ...filters,
  //       idpdvs: filters.idpdvs ? filters.idpdvs.join(',') : '',
  //       status: filters.status === 'Todos' ? '' : filters.status
  //     });
  //     console.log('teste> ' + filters.geral);
  //     const response = await axios.get(url + `/api/v1/user/perfis?${queryParams}`, {
  //       headers: ({
  //         Authorization: 'Bearer ' + token
  //       })
  //     });
  //     const { data, totalcount } = response.data;

  //     setPerfis(data);
  //     setTotalPages(Math.ceil(totalcount / PAGE_SIZE));

  //     //console.log(data);

  //     setIsLoggedIn(true);
  //   } catch {
  //     setIsLoggedIn(false);
  //     navigate('/signin');
  //   } finally {

  //     setLoading(false);
  //   };
  // }




  const handleChangeStatus = async (perfil) => {
    const confirmChange = window.confirm(`Tem certeza que deseja ${perfil.status === 'ATIVO' ? 'desativar' : 'ativar'} este perfil?`);

    if (!confirmChange) return; // Se o usuário cancelar, não prosseguir.

    setLoading(true);
    try {
      const token = await localStorage.getItem('token');
      const statusTemp = perfil.status;
      perfil.status = perfil.status === 'ATIVO' ? 'INATIVO' : perfil.status === 'INATIVO' ? 'ATIVO' : perfil.status;

      if (statusTemp !== perfil.status) {
        await axios.post(url + `/api/v1/user/atualizaStatus/${perfil._id}`, { status: perfil.status }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
    } catch {
      navigate('/signin');
    } finally {
      setLoading(false);
    }
  };



  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', maxWidth: '100%' }}>
      {loading ? <LoaderOverlay loading={loading} /> :
        <Grid container spacing={2} sx={{ marginTop: 12, justifyContent: 'center' }}  >
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Typography variant="h4" fontWeight={'bold'} fontFamily={'Ubuntu'} component="h1" sx={{ display: 'flex', justifyContent: 'center' }}>Perfis</Typography>
          </Grid>

          {/*<Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', marginTop: 2 }}>
            <Sidebar filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> 

          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 6 }}>

            <FilterComponent filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} />
           <Sidebar2 open={true} filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> 

        </Grid> */}


          <Grid item sx={{ display: 'flex', justifyContent: 'right' }} alignSelf='end' justifySelf={'end'}>
            <List sx={{ marginTop: 1 }}>

              {perfis.length > 0 ? (
                <>
                  <TableContainer sx={{ display: 'flex', borderSpacing: '0 8px', width: '100%' }} >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '150px' }}>Nome</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '120px' }}>Empresa</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '120px' }}>CNPJ</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '100px' }}>idpdv</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '130px' }}>Telefone</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '150px' }}>Email</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '100px' }}>Data/Hora</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '50px' }}>Status</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', minWidth: '50px' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {perfis.map((perfil) => (
                          <TableRow key={perfil._id} sx={{
                            cursor: 'pointer'
                          }} >
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.fullname ? perfil.fullname : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.empresa ? perfil.empresa : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.cnpj ? perfil.cnpj : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.idpdv ? perfil.idpdv : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{formatPhoneNumber('+' + perfil.numero)}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.email ? perfil.email : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} onClick={() => handlePerfilClick(perfil)}>{perfil.createdDate ? new Date(perfil.createdDate).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'}</TableCell>
                            <TableCell sx={{ fontSize: '13px' }} style={{ color: perfil.status === 'ATIVO' ? 'green' : perfil.status === 'EMANALISE' ? 'blue' : 'red' }} onClick={() => handleChangeStatus(perfil)}>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  fontSize: '12px',
                                  backgroundColor: getStatusColor(perfil.status),
                                  color: '#fff',
                                  border: 'none',
                                  borderRadius: '20px',
                                  pointerEvents: 'none',
                                  minWidth: '80px',
                                  textAlign: 'center',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {perfil.status
                                  ? perfil.status === 'ATIVO'
                                    ? perfil.status
                                    : perfil.status === 'EMANALISE'
                                      ? 'EM ANALISE'
                                      : perfil.status === 'INATIVO'
                                        ? 'INATIVO'
                                        : 'BLOQUEADO'
                                  : '-'}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" justifyContent="space-between">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleEdit(perfil)}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  color="secondary"
                                  size="small"
                                  onClick={() => handleDelete(perfil._id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Modal open={open} onClose={handleClose}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <h2>Editar Perfil</h2>
                      <TextField
                        label="Nome"
                        fullWidth
                        margin="normal"
                        value={selectedPerfil?.fullname || ''}
                        onChange={(e) => setSelectedPerfil((prev) => ({ ...prev, fullname: e.target.value }))}
                      />
                      <TextField
                        label="Empresa"
                        fullWidth
                        margin="normal"
                        value={selectedPerfil?.empresa || ''}
                        onChange={(e) => setSelectedPerfil((prev) => ({ ...prev, empresa: e.target.value }))}
                      />
                      <TextField
                        label="CNPJ"
                        fullWidth
                        margin="normal"
                        value={selectedPerfil?.cnpj || ''}
                        onChange={(e) => setSelectedPerfil((prev) => ({ ...prev, cnpj: formatCNPJ(e.target.value) }))}
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        value={selectedPerfil?.email || ''}
                        onChange={(e) => setSelectedPerfil((prev) => ({ ...prev, email: e.target.value }))}
                      />
                      <TextField
                        label="Telefone"
                        fullWidth
                        margin="normal"
                        value={selectedPerfil?.numero || ''}
                        onChange={(e) => setSelectedPerfil((prev) => ({ ...prev, numero: e.target.value }))}
                      />
                      <Button variant="contained" color="primary" onClick={handleSave}>
                        Salvar
                      </Button>
                    </Box>
                  </Modal>

                  {perfis.map((perfil) => (
                    <Card key={perfil._id} variant="outlined" sx={{ width: '100%', display: { xs: 'flex', md: 'none' }, marginBottom: 2 }} onClick={() => handlePerfilClick(perfil)}>
                      <CardContent sx={{ width: '100%' }}>
                        <Grid container spacing={2} sx={{ marginRight: 1 }}>
                          {/* Primeira coluna para o status */}
                          <Grid alignContent={'center'} justifyContent={'flex-start'} item xs={3}>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                fontSize: '12px',
                                backgroundColor: getStatusColor(perfil.status),
                                color: '#fff',
                                border: 'none',
                                borderRadius: '20px',
                                pointerEvents: 'none',
                                minWidth: '80px',
                                textAlign: 'center',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {perfil.status}
                            </Button>
                          </Grid>
                          {/* Segunda coluna para nome, valor e descrição */}
                          <Grid container xs={9} spacing={2} sx={{ marginTop: 0.01 }} justifyContent={'flex-end'} alignContent={'center'} >
                            <Grid item xs={6}>
                              <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} component="div">{perfil.nomeCompleto}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'right' }}> {formatCurrency(perfil.idpdv)}</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>Descrição: {perfil.createdDate}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                  ))}

                </>
              ) : (
                <Typography component="h2" variant="h5">Nenhum pagamento encontrado</Typography>
              )
              }

            </List >
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    component="button"
                    {...item}
                    onClick={(event) => handlePageChange(event, item.page)}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>}

    </Container >
  );

}

