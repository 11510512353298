import * as React from 'react';
import {
  Alert,
  Box, Button, Container, Grid, IconButton, InputAdornment, Paper, Snackbar, Tab, Tabs, TextField, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../utils/constants';
import Telefone from '../utils/Telefone';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function Perfil({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('PixTick | Perfil');
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [senhaValida, setSenhaValida] = React.useState(false);
  const [qtdCaracteresValida, setQtdCaracteresValida] = React.useState(false);
  const [temMaiuscula, setTemMaiuscula] = React.useState(false);
  const [temMinuscula, setTemMinuscula] = React.useState(false);
  const [temNumero, setTemNumero] = React.useState(false);
  const [temCaracterEspecial, setTemCaracterEspecial] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const patternUPPER = new RegExp("^(?=.*[A-Z])");
  const patternLOWER = new RegExp("^(?=.*[a-z])");
  const patternNUMBER = new RegExp("^(?=.*\\d)");
  const patternESPECIAL = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
  const [openDev, setOpenDev] = React.useState(false);
  const [user, setUser] = React.useState({
    fullname: '',
    email: '',
    password: ''
  });
  const [newEmail, setNewEmail] = React.useState('');
  const [senhas, setSenhas] = React.useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [state, setState] = React.useState({
    ddi: '+55',
    phone: '',
    error: ''
  });

  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        const response = await axios.get(url + `/api/v1/user/perfil`, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
        const { data } = response;
        setUser(data.user);
        setNewEmail(data.user.email); // Only update once data is fetched
        const phone = formatPhoneNumber('+' + data.user.numero);
        setState({ ...state, phone: phone.phone, ddi: phone.ddi });
        setIsLoggedIn(true);
      } catch (e) {
        setError('Erro ao recuperar dados');
      }
    }
    fetchData();
  }, [navigate, setIsLoggedIn]); // Removed `setState, state` to avoid unnecessary re-renders

  const handleAlterarSenha = async (event) => {
    event.preventDefault();
    try {
      const token = await localStorage.getItem('token');

      // Verificações de validação antes de tentar alterar a senha
      if (!senhaValida) {
        setMessage("A senha não atende aos critérios");
        setOpen(true);
        return;
      }

      if (senhas.newPassword !== senhas.confirmPassword) {
        setMessage("Senhas não conferem");
        setOpen(true);
        return;
      }

      const body = {
        user: {
          email: user.email,
          password: user.password,
        },
        newPassword: senhas.newPassword
      };

      // Chamada para a API
      const updateResponse = await axios.post(url + '/api/v1/user/atualizaSenha', body, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      // Verificação da resposta corrigida
      if (updateResponse.data.status === "Senha atualizada com sucesso") {
        setMessage("Senha alterada com sucesso!");
        setOpen(true);
        navigate('/perfil');
      } else {
        setMessage("Erro ao alterar senha: " + (updateResponse.data.message || "Mensagem não disponível"));
        setOpen(true);
      }

    } catch (error) {
      console.log('Erro:', error.response ? error.response.data : error);
      setMessage("Senha atual incorreta.");
      setOpen(true);
    }
  };

  const handleIsValidPassword = (password) => {
    setQtdCaracteresValida(false);
    setTemMaiuscula(false);
    setTemMinuscula(false);
    setTemNumero(false);
    setTemCaracterEspecial(false);
    setSenhaValida(false);
    if (password.length >= 8 && password.length <= 16) setQtdCaracteresValida(true);
    if (patternUPPER.test(password)) setTemMaiuscula(true);
    if (patternLOWER.test(password)) setTemMinuscula(true);
    if (patternNUMBER.test(password)) setTemNumero(true);
    if (patternESPECIAL.test(password)) setTemCaracterEspecial(true);
    if (password.length >= 8 && password.length <= 16 && patternUPPER.test(password) && patternLOWER.test(password) && patternNUMBER.test(password) && patternESPECIAL.test(password)) {
      setSenhaValida(true);
      return true;
    }
    return false;
  }

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleAlterarDados = async () => {
    try {
      const token = await localStorage.getItem('token');
      const body = {
        user: {
          email: user.email,
          phone: (state.ddi + state.phone).replace(/[()\s+-]/g, "")
        },
        newEmail: newEmail,
      };
      const { data } = await axios.post(url + '/api/v1/user/atualizaDados', body, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      localStorage.setItem('token', data.token);
      setMessage("Dados alterados com sucesso!");
      setOpen(true);  // Exibe a mensagem de sucesso
      navigate('/perfil');
    } catch (error) {
      console.log(error);
      setMessage("Erro ao alterar dados.");
      setOpen(true);  // Exibe a mensagem de erro
    }
  };

  function formatPhoneNumber(phoneNumber) {
    // Retorna o valor de entrada se for null ou undefined
    if (!phoneNumber) return phoneNumber;

    // Define as máscaras para diferentes códigos de país
    const masks = {
      '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
      '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
      '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
      '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
    };

    // Encontra o código de país (ddi) que corresponde ao início do número de telefone
    let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
    if (!ddi) return phoneNumber;

    // Remove o código de país do número de telefone
    phoneNumber = phoneNumber.replace(ddi, '');

    // Obtém a máscara correspondente ao código de país encontrado
    const mask = masks[ddi].mask;

    // Extrai apenas os dígitos do número de telefone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    let formattedPhoneNumber = '';
    let maskIndex = 0;

    // Aplica a máscara ao número de telefone numérico
    for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
      if (mask[i] === 'x') {
        formattedPhoneNumber += numericPhoneNumber[maskIndex];
        maskIndex++;
      } else {
        formattedPhoneNumber += mask[i];
      }
    }

    return { ddi, phone: formattedPhoneNumber };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeNewPassword = (event) => {
    const { value } = event.target;
    setSenhas({ ...senhas, newPassword: value });
    handleIsValidPassword(value);
  }

  const handleNewEmail = (event) => {
    setNewEmail(event.target.value);
  }

  return (
    <Container sx={{ mt: 12 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 2
        }}
      >

        <Tabs value={tabValue} onChange={handleChange} aria-label="profile tabs"  >
          <Tab label="Dados Pessoais" />
          <Tab label="Segurança" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Paper
          sx={{
            padding: 2, width: { xs: '90%', sm: '70%', md: '50%', lg: '44%' },
            marginLeft: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
            marginRight: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
          }}
        >
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h4' >{user.fullname}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' >IDPDV: {user.idpdv ? user.idpdv : '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' >Empresa: {user.empresa ? user.empresa : '-'}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  InputProps={{
                    style: { borderRadius: '20px' },
                  }}
                  disabled
                />
              </Grid>


              <Grid item xs={12} sm={12}>
                <Telefone state={state} setState={setState} />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} width='100%'>

              <Button fullWidth sx={{ marginTop: 2 }} variant="contained" color="primary" onClick={handleAlterarDados}>Salvar</Button>
            </Box>
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Paper
          sx={{
            padding: 2, width: { xs: '90%', sm: '70%', md: '50%', lg: '44%' },
            marginLeft: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
            marginRight: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
          }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h4' >{user.fullname}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' >IDPDV: {user.idpdv ? user.idpdv : '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' >Empresa: {user.empresa ? user.empresa : '-'}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="currentPassword"
                  label="Senha Atual"
                  type={showCurrentPassword ? 'text' : 'password'}
                  id="currentPassword"
                  value={user.password}
                  onChange={(e) => setUser({ ...user, password: e.target.value })}
                  InputProps={{
                    style: { borderRadius: '20px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleCurrentPasswordVisibility}
                          edge="end"
                        >
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="newPassword"
                  label="Nova Senha"
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  value={senhas.newPassword}
                  onChange={handleChangeNewPassword}
                  InputProps={{
                    style: { borderRadius: '20px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleNewPasswordVisibility}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Typography style={{ color: senhaValida ? 'green' : 'red' }} id='todosCriterios'>
                  A senha deve ter pelo menos:
                </Typography>
                <Typography style={{ color: qtdCaracteresValida ? 'green' : 'red' }} id='qtdCaracteres'>
                  * 8 a 16 caracteres
                </Typography>
                <Typography style={{ color: temMaiuscula ? 'green' : 'red' }} id='temMaiuscula'>
                  * Pelo menos 1 maiúscula
                </Typography>
                <Typography style={{ color: temMinuscula ? 'green' : 'red' }} id='temMinuscula'>
                  * Pelo menos 1 minúscula
                </Typography>
                <Typography style={{ color: temNumero ? 'green' : 'red' }} id='temNumero'>
                  * Pelo menos 1 número
                </Typography>
                <Typography style={{ color: temCaracterEspecial ? 'green' : 'red' }} id='temCaracterEspecial'>
                  * Pelo menos 1 caractere especial
                </Typography >
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirme a Senha"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={senhas.confirmPassword}
                  onChange={(e) => setSenhas({ ...senhas, confirmPassword: e.target.value })}
                  InputProps={{
                    style: { borderRadius: '20px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}

                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} width='100%'>
              <Button fullWidth sx={{ marginTop: 2 }} variant="contained" color="primary" onClick={handleAlterarSenha}>Salvar</Button>
            </Box>
          </Box>
        </Paper>
      </TabPanel>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpen(false)} severity={message.includes('sucesso') ? 'success' : 'error'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

