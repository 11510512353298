import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Container, List, Pagination, PaginationItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../utils/constants';
import { HorizontalLine, LoaderOverlay } from '../utils/components';
import Sidebar2 from '../utils/SideBar2';
import { LineAxis } from '@mui/icons-material';

// Função para formatar a data no formato 'yyyy-MM-ddTHH:mm'
function formatDateTimeLocal(date) {
    const pad = (number) => number.toString().padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

// Função para obter o primeiro dia do mês atual
function getFirstDayOfMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0); // Primeiro dia do mês às 00:00
}

// Função para obter o final do dia de hoje
function getEndOfDay() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59); // Hoje às 23:59
}

export default function PagamentoList({ setIsLoggedIn, usePageTitle }) {
    usePageTitle('PixTick | Transações');
    const [transactions, setTransactions] = React.useState([]);
    const [idpdvs, setIdpdvs] = React.useState([]);
    const [amountPaid, setAmountPaid] = React.useState(0);
    const [amountPending, setAmountPending] = React.useState(0);
    const [amountExpired, setAmountExpired] = React.useState(0);
    const [amountPaidF, setAmountPaidF] = React.useState(0);
    const [amountPendingF, setAmountPendingF] = React.useState(0);
    const [amountExpiredF, setAmountExpiredF] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);

    const [copied, setCopied] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [filtrado, setFiltrado] = React.useState(false);
    //como saber quantos propriedades o objeto tem
    //console.log(Object.keys(location).length);
    const defaultFilters = {
        descricao: '',
        nome: '',
        dataInicio: formatDateTimeLocal(getFirstDayOfMonth()),
        dataFim: formatDateTimeLocal(getEndOfDay()),
        telefone: '',
        id: '',
        idTransacao: '',
        idpdvs: [],
        status: 'Todos',
        geral: '',
        itensPagina: 20
    };

    const [filters, setFilters] = React.useState(defaultFilters);

    const loadFiltersFromUrl = () => {
        const queryParams = new URLSearchParams(location.search);
        const page = parseInt(queryParams.get('page')) || 1;
        setCurrentPage(page);
        const isFiltrado = queryParams.get('filtrado') === "true" || false;
        setFiltrado(isFiltrado);

        const newFilters = { ...defaultFilters };
        if (queryParams.size !== 0) {
            for (const key of Object.keys(defaultFilters)) {
                if (queryParams.has(key)) {
                    newFilters[key] = queryParams.get(key);
                } else if (key === 'dataInicio') {
                    newFilters[key] = '';
                } else if (key === 'dataFim') {
                    newFilters[key] = '';
                }
            }
        }
        setFilters(newFilters);
    };

    React.useEffect(() => {
        loadFiltersFromUrl();
    }, [location.search]);

    // React.useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const page = parseInt(queryParams.get('page')) || 1;
    //     setCurrentPage(page);

    // }, [location.search]);

    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                const queryParams = new URLSearchParams({
                    page: currentPage,
                    ...filters,
                    dataInicio: convertToUTCString(filters.dataInicio),
                    dataFim: convertToUTCString(filters.dataFim),
                    status: filters.status === 'Todos' ? '' : filters.status
                });

                const response = await axios.get(url + `/api/v1/pagamento?${queryParams}`, {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    })
                });
                const { data, totalcount, totalAmountPaid, totalAmountPending, totalAmountExpired, usersIdpdvs, totalAmountPaidF, totalAmountPendingF, totalAmountExpiredF } = response.data;
                usersIdpdvs ? setIdpdvs(usersIdpdvs) : setIdpdvs([]);
                setTransactions(data);
                setAmountPaid(totalAmountPaid);
                setAmountPending(totalAmountPending);
                setAmountExpired(totalAmountExpired);
                setAmountPaidF(totalAmountPaidF);
                setAmountPendingF(totalAmountPendingF);
                setAmountExpiredF(totalAmountExpiredF);
                setTotalPages(Math.ceil(totalcount / filters.itensPagina));

                //console.log(data);


                setIsLoggedIn(true);
            } catch {
                setIsLoggedIn(false);
                navigate('/signin');
            } finally {
                setLoading(false);
                //console.log(transactions);

            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn, currentPage, filters]);

    // const handlePageChange = (event, pageNumber) => {
    //     setCurrentPage(pageNumber);

    //     navigate(`?page=${pageNumber}`);
    // };

    // const handlePageChange = (event, pageNumber) => {
    //     setCurrentPage(pageNumber);
    //     navigate(`?${new URLSearchParams({ ...filters, page: pageNumber }).toString()}`);
    // };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
        navigate(`?${new URLSearchParams(removeEmptyFilters({ ...filters, page: pageNumber, filtrado: filtrado })).toString()}`);
    };

    const removeEmptyFilters = (filters) => {
        const cleanedFilters = {};
        for (const key in filters) {
            if (filters[key] !== '' && filters[key] !== null && filters[key] !== undefined && !(Array.isArray(filters[key]) && filters[key].length === 0)) {
                cleanedFilters[key] = filters[key];
            }
        }
        return cleanedFilters;
    }

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return phoneNumber;

        // Definir máscaras para cada DDI
        const masks = {
            '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
            '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
            '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
            '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
            // Adicione outras máscaras conforme necessário
        };

        // Detectar o DDI do número de telefone
        let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
        if (!ddi) return phoneNumber; // Se não for encontrado nenhum DDI correspondente, retorne o número de telefone sem formatação
        phoneNumber = phoneNumber.replace(ddi, ''); // Remover o DDI do número de telefone
        // Obter a máscara correspondente ao DDI detectado
        const mask = masks[ddi].mask;

        // Remover caracteres não numéricos do número de telefone
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

        // Aplicar máscara ao número de telefone
        let formattedPhoneNumber = '';
        let maskIndex = 0;
        for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
            if (mask[i] === 'x') {
                formattedPhoneNumber += numericPhoneNumber[maskIndex];
                maskIndex++;
            } else {
                formattedPhoneNumber += mask[i];
            }
        }
        //ddi + formattedPhoneNumber;

        return ddi + ' ' + formattedPhoneNumber;
    }

    const formatCurrency = (value) => {
        if (!value) return value;

        // Formata o valor em moeda brasileira (pt-BR)
        return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    function getStatusColor(status) {
        switch (status) {
            case 'Pendente':
                return '#0000FF'; // Amarelo
            case 'PAGO':
                return '#008000'; // Verde
            case 'Concluído':
                return '#0000FF'; // Azul
            case 'Falhou':
                return '#FF0000'; // Vermelho
            default:
                return '#FF0000'; // Cinza (ou qualquer outra cor padrão)
        }
    }

    const handleTransactionClick = (transaction) => {
        console.log(transaction);
        navigate(`/relatorio-pagamento/${transaction.id}`)
        //setSelectedTransaction(transaction);
    };

    const convertToUTCString = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString();
    };

    const handleFilter = async () => {
        setLoading(true);
        setCurrentPage(1);
        await setFiltrado(true);
        navigate(`?${new URLSearchParams(removeEmptyFilters({ ...filters, page: currentPage, filtrado: true })).toString()}`);
        try {
            const token = await localStorage.getItem('token');
            const queryParams = new URLSearchParams({
                page: currentPage,
                ...filters,
                dataInicio: convertToUTCString(filters.dataInicio),
                dataFim: convertToUTCString(filters.dataFim),
                idpdvs: filters.idpdvs ? filters.idpdvs.join(',') : '',
                status: filters.status === 'Todos' ? '' : filters.status
            });

            //console.log('teste> ' + filters.dataInicio);
            // console.log('teste> ' + filters.dataFim);
            // console.log('teste> ' + filters.geral);
            const response = await axios.get(url + `/api/v1/pagamento?${queryParams}`, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            });
            const { data, totalcount, totalAmountPaid, totalAmountPending, totalAmountExpired, usersIdpdvs, totalAmountPaidF, totalAmountPendingF, totalAmountExpiredF } = response.data;
            usersIdpdvs ? setIdpdvs(usersIdpdvs) : setIdpdvs([]);
            setTransactions(data);
            setAmountPaid(totalAmountPaid);
            setAmountPending(totalAmountPending);
            setAmountExpired(totalAmountExpired);
            setAmountPaidF(totalAmountPaidF);
            setAmountPendingF(totalAmountPendingF);
            setAmountExpiredF(totalAmountExpiredF);

            setTotalPages(Math.ceil(totalcount / filters.itensPagina));

            //console.log(data);

            setIsLoggedIn(true);
        } catch {
            setIsLoggedIn(false);
            navigate('/signin');
        } finally {
            setLoading(false);
        };
    }

    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            {loading ? <LoaderOverlay loading={loading} /> :
                // <Grid container spacing={2} sx={{ marginTop: 10, marginLeft: sidebarOpen ? '10%' : 0, justifyContent: 'center' }}  >
                <Grid container spacing={2} justifyContent="center" sx={{ width: { xs: '100%', sm: '90%', md: 'auto' }, marginLeft: { xs: 0, md: sidebarOpen ? '20%' : 'auto', xl: 0 }, marginRight: { xs: 0, md: sidebarOpen ? 0 : 'auto', xl: 0 } }}>
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Typography variant="h5" fontWeight={'bold'} fontFamily={'Ubuntu'} component="h1" sx={{ display: 'flex', justifyContent: 'center' }}>Relatório das Transações</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#3BB273',
                                border: '1px solid lightgray',
                                borderRadius: 2,
                                marginTop: 1,
                                padding: 2,
                                width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                margin: 'auto',
                            }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color="white" fontSize={{ xs: 20, md: 25 }} fontWeight="bold" fontFamily="Ubuntu" variant="h6" align="center">Recebido</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color="white" fontSize={{ xs: 15, md: 20 }} fontWeight="bold" fontFamily="Ubuntu" variant="h6" align="center">{formatCurrency(amountPaid)}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#4D9DE0',
                                border: '1px solid lightgray',
                                borderRadius: 2,
                                marginTop: 1,
                                padding: 2,
                                width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                margin: 'auto',
                            }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color='white' fontSize={{ xs: 20, md: 25 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >Pendente</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color='white' fontSize={{ xs: 15, md: 20 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >{formatCurrency(amountPending)}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#E15554',
                                border: '1px solid lightgray',
                                borderRadius: 2,
                                marginTop: 1,
                                padding: 2,
                                width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                margin: 'auto',
                            }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color='white' fontSize={{ xs: 20, md: 25 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >Expirado</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography color='white' fontSize={{ xs: 15, md: 20 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >{formatCurrency(amountExpired)}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&
                        <Grid item xs={12}>
                            <HorizontalLine />
                        </Grid>}
                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>

                            <Typography >{(filters?.descricao ? "Descrição: " + filters?.descricao + ((filters.nome || filters.dataInicio || filters.dataFim || filters.telefone) ? ", " : "") : "") +
                                (filters?.nome ? "Nome: " + filters?.nome + ((filters.dataInicio || filters.dataFim || filters.telefone) ? ", " : "") : "") +
                                (filters?.dataInicio ? "Data inicio: " + filters?.dataInicio + ((filters.dataFim || filters.telefone) ? ", " : "") : "") +
                                (filters?.dataFim ? "Data fim: " + filters?.dataFim + ((filters.telefone) ? ", " : "") : "") + (filters?.telefone ? "Telefone: " + filters?.telefone : "")} </Typography>

                        </Grid>}
                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper
                                elevation={3}
                                sx={{
                                    backgroundColor: '#3BB273',
                                    border: '1px solid lightgray',
                                    borderRadius: 2,
                                    marginTop: 1,
                                    padding: 2,
                                    width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                    margin: 'auto',
                                }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 15, md: 20 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >RECEBIDO</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 12, md: 15 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >{formatCurrency(amountPaidF)}</Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper
                                elevation={3}
                                sx={{
                                    backgroundColor: '#4D9DE0',
                                    border: '1px solid lightgray',
                                    borderRadius: 2,
                                    marginTop: 1,
                                    padding: 2,
                                    width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                    margin: 'auto',
                                }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 15, md: 20 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >Pendente</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 12, md: 15 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >{formatCurrency(amountPendingF)}</Typography>
                                </Grid>
                            </Paper>
                        </Grid>}
                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper
                                elevation={3}
                                sx={{
                                    backgroundColor: '#E15554',
                                    border: '1px solid lightgray',
                                    borderRadius: 2,
                                    marginTop: 1,
                                    padding: 2,
                                    width: { xs: '90%', sm: '75%', md: '66%', lg: '66%' },
                                    margin: 'auto',
                                }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 15, md: 20 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >Expirado</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color='white' fontSize={{ xs: 12, md: 15 }} fontWeight={'bold'} fontFamily={'Ubuntu'} variant="h6" component="div" align='center' >{formatCurrency(amountExpiredF)}</Typography>
                                </Grid>
                            </Paper>
                        </Grid>}

                    {filtrado && (filters.descricao || filters.nome || filters.dataInicio || filters.dataFim || filters.telefone || filters.status) &&
                        <Grid item xs={12}>
                            <HorizontalLine />
                        </Grid>}

                    <Grid item xs={12} sx={{ display: { xs: 'flex' }, marginTop: 2, justifyContent: 'center', }}>

                        {/* <FilterComponent filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> */}
                        <Sidebar2 open={sidebarOpen} setOpen={setSidebarOpen} filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} />

                    </Grid>


                    <Grid item xs={12} md={10} lg={11} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <List sx={{ marginTop: 1 }}>

                            {transactions.length > 0 ? (
                                <>
                                    <TableContainer sx={{ display: { xs: 'none', md: 'flex' }, borderSpacing: '0 8px' }} >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '2px 4px' }}>Descrição</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '2px 4px' }}>Nome</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '2px 4px', width: '140px', sm: 'none', md: 'flex' }}>Data / Hora</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '2px 4px', width: '135px' }}>Telefone</TableCell>
                                                    <TableCell align='right' sx={{ fontWeight: 'bold', padding: '2px 4px' }}>Valor</TableCell>
                                                    <TableCell align='center' sx={{ fontWeight: 'bold', padding: '2px 4px' }}>Status</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '50px', padding: '2px 4px' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody >
                                                {transactions.map((transaction) => (
                                                    <TableRow key={transaction._id} sx={{ height: '0px' }} >
                                                        <TableCell sx={{ height: '30px', padding: '2px 4px' }} onClick={() => handleTransactionClick(transaction)}>{transaction.description ? transaction.description : '-'}</TableCell>
                                                        <TableCell sx={{ height: '30px', padding: '2px 4px' }} onClick={() => handleTransactionClick(transaction)}>{transaction.nomeCompleto ? transaction.nomeCompleto : '-'}</TableCell>
                                                        <TableCell sx={{ height: '30px', padding: '2px 4px' }} onClick={() => handleTransactionClick(transaction)}>{transaction.date ? new Date(transaction.date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'}</TableCell>
                                                        <TableCell sx={{ height: '30px', padding: '2px 4px' }} onClick={() => handleTransactionClick(transaction)}>{formatPhoneNumber('+' + transaction.wppNumber)}</TableCell>
                                                        <TableCell align='right' sx={{ fontWeight: 'bold', height: '30px', padding: '2px 4px' }} onClick={() => handleTransactionClick(transaction)}>{transaction.amount ? formatCurrency(transaction.amount) : '-'}</TableCell>
                                                        <TableCell sx={{ height: '30px', padding: '2px 4px' }} style={{ color: transaction.status === 'PAGO' ? 'green' : transaction.status === 'Pendente' ? 'blue' : 'red' }} onClick={() => handleTransactionClick(transaction)}>
                                                            <Button
                                                                variant="contained"
                                                                size=""
                                                                sx={{
                                                                    backgroundColor: getStatusColor(transaction.status), // Função getStatusColor para determinar a cor com base no status
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    borderRadius: '20px',
                                                                    padding: '8px 16px',
                                                                    pointerEvents: 'none',
                                                                    width: '110px'
                                                                }}>
                                                                {transaction.status}
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell sx={{ width: '100px', padding: '2px 4px' }} >

                                                            {transaction.status === 'Pendente' && <Button
                                                                fullWidth

                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(transaction.pixCopyPaste);
                                                                    setCopied(true); // Define o estado para indicar que o código foi copiado
                                                                    setTimeout(() => {
                                                                        setCopied(false); // Reseta o estado após alguns segundos
                                                                    }, 2000);
                                                                }}
                                                            >
                                                                Codigo Pix
                                                            </Button>}
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    {transactions.map((transaction) => (
                                        <Card key={transaction._id} variant="outlined" sx={{ width: '100%', display: { xs: 'flex', md: 'none' }, marginBottom: 2 }} onClick={() => handleTransactionClick(transaction)}>
                                            <CardContent sx={{ width: '100%' }}>
                                                <Grid container spacing={2} sx={{ marginRight: 1 }}>
                                                    {/* Primeira coluna para o status */}
                                                    <Grid alignContent={'center'} justifyContent={'flex-start'} item xs={3}>
                                                        <Button
                                                            variant="contained"
                                                            size=""
                                                            sx={{
                                                                backgroundColor: getStatusColor(transaction.status),
                                                                color: '#fff',
                                                                border: 'none',
                                                                borderRadius: '20px',
                                                                padding: '8px 16px',
                                                                pointerEvents: 'none',
                                                                width: '70px',
                                                                fontSize: '0.7rem'
                                                            }}>
                                                            {transaction.status}
                                                        </Button>
                                                    </Grid>
                                                    {/* Segunda coluna para nome, valor e descrição */}
                                                    <Grid container xs={9} spacing={2} sx={{ marginTop: 0.01 }} justifyContent={'flex-end'} alignContent={'center'} >
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'left' }} component="div">{transaction.nomeCompleto}</Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography variant="body1" sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'right' }}> {formatCurrency(transaction.amount)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Descrição: {transaction.description}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                    ))}

                                </>
                            ) : (
                                <Typography component="h2" variant="h5">Nenhum pagamento encontrado</Typography>
                            )
                            }

                        </List >
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        component="button"
                                        {...item}
                                        onClick={(event) => handlePageChange(event, item.page)}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={copied}
                autoHideDuration={2000}
                onClose={() => setCopied(false)}
            >
                <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: '100%' }}>
                    Código copiado!
                </Alert>
            </Snackbar>
        </Container >
    );

}

