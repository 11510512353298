import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { url } from '../utils/constants';
import axios from 'axios';
import { initMercadoPago } from '@mercadopago/sdk-react'
initMercadoPago('APP_USR-8eb5c650-01a2-481c-8a68-2b9369b17b94');
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function FinalizaPagamento({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('PixTick | Finaliza Pagamento');
  const [loading, setLoading] = React.useState(false);
  const [transaction, setTransaction] = React.useState([]);
  const query = useQuery();
  const id = query.get('id');
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchTransaction() {
      try {

        const response = await axios.get(url + `/api/v1/finalizaPagamento`, {
          headers: ({
            Authorization: 'Bearer ' + id
          })
        });
        const { data } = response.data;
        setTransaction(data);

        //console.log(data);

        setIsLoggedIn(true);
      } catch (error) {
        // const response = await fetch(`http://localhost:3001/transaction/${id}`);
        // const data = await response.json();
        // setTransaction(data);
        setLoading(false);
      }
    }
    fetchTransaction();
  }, [navigate, id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {

      const body = {
        "transaction": transaction
      }
      const response = await axios.put(url + `/api/v1/finalizaPagamento`, body, {
        headers: ({
          Authorization: 'Bearer ' + id
        })
      });
      const { data } = response.data;
      setTransaction(data);

      //console.log(data);

      setIsLoggedIn(true);
    } catch (error) {
      // const response = await fetch(`http://localhost:3001/transaction/${id}`);
      // const data = await response.json();
      // setTransaction(data);
      setLoading(false);
    }
  }

  const handelOnChangeCardNumber = (event) => {

    const cardNumber = event.target.value;
    const card = document.querySelector('input[data-checkout="cardNumber"]');
    const cardNumberValue = cardNumber.replace(/ /g, '');
    card.value = cardNumberValue;
    card.onchange();
  }

  return (
    <Container sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}>

      <Box marginTop={10} justifyContent='center' >

        <Paper
          elevation={3}
          sx={{
            marginTop: 10, padding: 2, width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' },
            marginLeft: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
            marginRight: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
          }}>
          {!loading && (
            <>
              <Typography variant="h6" component="div" align='center' >Finaliza Pagamento</Typography>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>

                <Grid item xs={6}>
                  <TextField type='text' label='Nome' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='Sobrenome' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='date' variant="outlined" label='Data de Nascimento' />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='CPF' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='Endereço' variant="outlined" />
                </Grid>
                <Grid item xs={12} justifyContent='center' sx={{ display: 'flex' }}>
                  <Typography variant="body1">Cartão</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='Titular' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='numero' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='Validade' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='CVV' variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' label='installments' id='form-checkout__installments' variant="outlined" />
                </Grid>

              </Grid>
            </>
          )}
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button type='submit' variant='contained' sx={{ width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' }, borderRadius: 2.5 }} onClick={handleSubmit}>Pagar</Button>
        </Box>
      </Box>
    </Container>
  );
}