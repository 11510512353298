import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Container, Paper, Snackbar } from '@mui/material';

import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../utils/constants';
import { LoaderOverlay } from '../utils/components';




export default function PerfilDetalhado({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('PixTick | Perfil detalhado');
  const id = useLocation().pathname.split('/').pop();
  const [perfil, setPerfil] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const navigate = useNavigate();


  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        //console.log('teste> ' + token);
        const response = await axios.get(url + `/api/v1/user/perfil/${id}`, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        });
        const { data } = response.data;
        setPerfil(data);

        console.log(data);

        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
        //navigate('/signin');
      } finally {
        setLoading(false);
        //console.log(transactions);

      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn, perfil._id, id]);


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return phoneNumber;

    // Definir máscaras para cada DDI
    const masks = {
      '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
      '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
      '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
      '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
      // Adicione outras máscaras conforme necessário
    };

    // Detectar o DDI do número de telefone
    let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
    if (!ddi) return phoneNumber; // Se não for encontrado nenhum DDI correspondente, retorne o número de telefone sem formatação
    phoneNumber = phoneNumber.replace(ddi, ''); // Remover o DDI do número de telefone
    // Obter a máscara correspondente ao DDI detectado
    const mask = masks[ddi].mask;

    // Remover caracteres não numéricos do número de telefone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Aplicar máscara ao número de telefone
    let formattedPhoneNumber = '';
    let maskIndex = 0;
    for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
      if (mask[i] === 'x') {
        formattedPhoneNumber += numericPhoneNumber[maskIndex];
        maskIndex++;
      } else {
        formattedPhoneNumber += mask[i];
      }
    }
    //ddi + formattedPhoneNumber;

    return ddi + ' ' + formattedPhoneNumber;
  }



  function getStatusColor(status) {
    switch (status) {
      case 'EMANALISE':
        return '#1976D2'; // Amarelo
      case 'ATIVO':
        return '#008000'; // Verde
      case 'Concluído':
        return '#0000FF'; // Azul
      case 'Falhou':
        return '#FF0000'; // Vermelho
      default:
        return '#FF0000'; // Cinza (ou qualquer outra cor padrão)
    }
  }


  const handleVoltar = () => {
    navigate('/perfis');
  }

  return (

    <Container sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <LoaderOverlay loading={loading} />

      <Box justifyContent='center' >

        <Paper

          elevation={3}
          sx={{
            marginTop: 10, padding: 2, width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' },
            marginLeft: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
            marginRight: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
          }}>
          {!loading && (
            <>
              <Typography variant="h6" component="div" align='center' >Detalhes do Perfil</Typography>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>

                <Grid item xs={5}>
                  <Typography variant="body1">Nome:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.fullname ? perfil.fullname : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Empresa:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.empresa ? perfil.empresa : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">CNPJ:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.cnpj ? perfil.cnpj : '-'}
                  </Typography>

                </Grid>

                <Grid item xs={5}>
                  <Typography variant="body1">IDPDV:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.idpdv ? perfil.idpdv : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">E-mail:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.email ? perfil.email : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Data / Hora:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.createdDate ? new Date(perfil.createdDate).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Telefone:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{perfil.numero ? formatPhoneNumber('+' + perfil.numero) : '-'}</Typography>

                </Grid>


                <Grid item xs={5}>
                  <Typography variant="body1">Status:</Typography>
                </Grid>
                <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <Typography variant="body1" color={getStatusColor(perfil.status)} sx={{ textAlign: 'right' }}>{perfil.status ? perfil.status : '-'}</Typography> */}
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: '12px',
                      backgroundColor: getStatusColor(perfil.status),
                      color: '#fff',
                      border: 'none',
                      borderRadius: '20px',
                      pointerEvents: 'none',
                      minWidth: '80px',
                      textAlign: 'center',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {perfil.status}</Button>
                </Grid>
                <Grid item xs={12}
                  alignContent='center'
                  justifyContent='center'
                >


                </Grid>

              </Grid>
            </>
          )}
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button type='cancel' variant='contained' sx={{ width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' }, borderRadius: 2.5 }} onClick={handleVoltar}>Voltar</Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: '100%' }}>
          Código copiado!
        </Alert>
      </Snackbar>
    </Container >

  );

}
