import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { masks } from './constants';
import { handleChange, handleDdiChange } from './validates';


const Telefone = ({ state, setState }) => {
    //console.log(state.ddi, state.phone);
    return (<Box sx={{ display: 'flex' }}>
        <FormControl sx={{ mr: 2 }}>
            <InputLabel id="ddi-label">DDI</InputLabel>
            <Select
                sx={{ borderRadius: 5 }}
                label="DDI"
                labelId="ddi-label"
                id="ddi"
                name="ddi"
                value={state.ddi}
                onChange={(event) => handleDdiChange(event, state, setState)}
            >
                {Object.keys(masks).map((countryCode) => (
                    <MenuItem key={countryCode} value={countryCode}>
                        {`${countryCode} - ${masks[countryCode].name}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <Box sx={{ flexGrow: 1 }}>
            <TextField
                required
                label="Número de telefone"
                type="tel"
                placeholder={masks[state.ddi].mask}
                value={state.phone}
                fullWidth
                name="phoneNumber"
                InputProps={{
                    style: {
                        borderRadius: '20px', // Aqui você define o borderRadius desejado
                    }
                }}
                onChange={(event) => handleChange(event, state, setState)}
            />

        </Box>
    </Box>);
}
export default Telefone;
