import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/constants';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoaderOverlay } from '../utils/components';
import { z } from "zod";

const theme = createTheme();

// Definindo um esquema de validação de campos do form
const userSchema = z.object({
  email: z.string()
    .min(1, 'Email é obrigatório')
    .refine((value) => value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
      message: 'Email inválido',
      path: ['email']
    }),

  password: z.string()
    .min(1, 'Senha é obrigatória')
    .refine((value) => value === '' || value.length >= 6, {
      message: 'A senha deve ter pelo menos 6 caracteres',
      path: ['password']
    })
});

export default function SignIn({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('PixTick | Signin');
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);

  // Verificar se o usuário já está logado ao montar o componente
  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        //console.log('teste> ' + token);
        if (token) {
          const { data } = await axios.get(url + '/api/v1/user/verifyToken', {
            headers: ({
              Authorization: 'Bearer ' + token
            })
          });

          setIsLoggedIn(true);
          navigate('/relatorio-pagamento')
        }
      } catch {
        setIsLoggedIn(false);

      } finally {
        setLoading(false);
      }


    }
    fetchData();
  }, [navigate, setIsLoggedIn]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const form = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      // Validação do formulário
      const validation = userSchema.safeParse(form);
      if (!validation.success) {
        setErrorMessage(validation.error.errors.map((err) => err.message).join(', '));
        return;
      }

      let response = await axios.post(url + '/api/v1/user/signin', form);
      console.log("responselogin: ", response.data)
      if (response && response.data) {
        const { data } = response;
        // localStorage.setItem('token', data.token);
        // setIsLoggedIn(true);
        // navigate('/video');
        if (data) {
          localStorage.removeItem('fulname');
          localStorage.setItem('fullname', data.user.fullname);
          localStorage.removeItem('role');
          localStorage.setItem('role', data.user.role);
          localStorage.removeItem('email');
          localStorage.setItem('email', data.user.email);
          localStorage.setItem('token', data.token);
          setIsLoggedIn(true);
          //quero colocar um timer para executar a função abaixo
          navigate('/relatorio-pagamento');

        } else {
          // Tratar o caso em que não há dados retornados pela requisição
          console.error('Dados não encontrados na resposta da requisição.');
        }
      } else {
        console.error('Resposta invalida.');
      }

    } catch (error) {
      console.log(error.response.data)
      setErrorMessage(error.response.data.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <LoaderOverlay loading={loading} />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >

          <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
            Entrar
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                style: {
                  borderRadius: '20px', // Aqui você define o borderRadius desejado
                }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                style: {
                  borderRadius: '20px', // Aqui você define o borderRadius desejado
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errorMessage && (
              <Typography component="p" variant="p" color="red">
                {errorMessage}
              </Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ borderRadius: 2.5, mt: 3, mb: 2 }}>
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                {<Link href="/ForgotPassword" variant="body2">
                  Esqueceu a senha?
                </Link>}
              </Grid>
              <Grid item>
                {/*<Link href="SignUp" variant="body2">
                  {"Don't have an account? Sign Up"}
          </Link>*/}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
